import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ResetFiltersButton.css'

import iconRefresh from '/images/icons/refresh.raw.svg'

export function ResetFiltersButton({ onClick, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <button data-x='click-reset-filters' className={cx(styles.component, layoutClassName)} {...{ onClick }}>
      <span>{__`reset-filters`}</span>
      <span className={styles.iconContainer}>
        <Icon icon={iconRefresh} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}
