import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useMediaQuery } from '@kaliber/use-media-query'

import { JOBS_PER_PAGE, routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { handleResponse } from '/machinery/handleResponse'
import { useJobsQueryString } from '/machinery/useJobsQueryString'
import { useDebounced } from '/machinery/useDebounced'
import { trackFilter } from '/machinery/tracking/pushToDataLayer'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { reportClientError } from '/machinery/reportClientError'
import { JobSearchbarAndFilter } from '/features/pageOnly/JobsOverview/JobSearchbarAndFilter'
import { ResetFiltersButton } from '/features/pageOnly/JobsOverview/ResetFiltersButton'
import { JobCard } from '/features/pageOnly/JobsOverview/JobCard'
import { Pagination } from '/features/pageOnly/JobsOverview/Pagination'

import mediaStyles from '/cssGlobal/media.css'
import styles from './JobsGridAndFilter.css'

const GAP = 48

export function JobsGridAndFilter({ jobFilters }) {
  const [lastChanged, setLastChanged] = React.useState(null)
  const [jobsQueryString, setQueryString] = useJobsQueryString()
  const debouncedSetQueryString = useDebounced(setQueryString)
  const filters = React.useMemo(
    () => prepareFilters(jobFilters, jobsQueryString, { setQueryString, debouncedSetQueryString, setLastChanged }),
    [jobFilters, jobsQueryString, setQueryString, debouncedSetQueryString, setLastChanged]
  )

  const { jobs, jobsAmount, status } = useJobsQuery(lastChanged)

  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)

  return (
    <div className={styles.component}>
      <JobSearchbarAndFilter {...{ filters, handleDrawerToggle, drawerIsOpen }} />
      <ContainerXl layoutClassName={styles.jobsGridLayout}>
        <JobsGrid {...{ jobs, jobsAmount, drawerIsOpen, onFilterReset, status }} />
      </ContainerXl>
    </div>
  )

  function handleDrawerToggle() {
    setDrawerIsOpen(!drawerIsOpen)
  }

  function onFilterReset() {
    setQueryString({})
  }
}

function JobsGrid({ jobs, jobsAmount, drawerIsOpen, status, onFilterReset }) {
  const [jobsQueryString] = useJobsQueryString()
  const { page } = jobsQueryString
  const maxPages = Math.ceil(jobsAmount / JOBS_PER_PAGE)

  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const columns = isViewportLg ? 3 : isViewportMd ? 2 : 1
  const { elementRef, height } = useCardHeight({ columns })
  const { isLoading } = status

  return (
    <div className={styles.componentJobsGrid}>
      <div ref={elementRef} style={{ '--min-height-card': `${height}px` }} className={styles.jobsGrid}>
        {Boolean(jobs.length) && !isLoading && jobs.map(({ _source: job }, i) => (
          <JobCard key={i} hideCursor={drawerIsOpen} layoutClassName={styles.jobCardLayout} {...{ job }} />
        ))}
        {isLoading && <JobsLoading layoutClassName={styles.jobsLoadingLayout} />}
        {!jobs.length && !isLoading && <NoJobsFound layoutClassName={styles.noJobsFoundLayout} {...{ onFilterReset }} />}
      </div>
      {maxPages > 1 && <Pagination currentPage={page} scrollToRef={elementRef} layoutClassName={styles.paginationLayout} {...{ maxPages }} />}
    </div>
  )
}

function NoJobsFound({ onFilterReset, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentNoJobsFound, layoutClassName)}>
      <div className={styles.textContainer}>
        <HeadingSm h={3}>{__`no-jobs-found`}</HeadingSm>
        <p>{__`change-filters`}</p>
      </div>
      <ResetFiltersButton onClick={onFilterReset} layoutClassName={styles.resetFilterLayout} />
    </div>
  )
}

function JobsLoading({ layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentJobsLoading, layoutClassName)}>
      <div className={styles.textContainer}>
        <HeadingSm h={3}>{__`jobs-loading`}</HeadingSm>
      </div>
    </div>
  )
}

function useCardHeight({ columns }) {
  const elementRef = React.useRef(null)
  const [height, setHeight] = React.useState(0)

  React.useEffect(
    () => {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        const { width: gridWidth } = elementRef.current.getBoundingClientRect()
        const cardWidth = (gridWidth - GAP * (columns - 1)) / columns
        const cardHeight = Math.max(cardWidth, 350)

        setHeight(cardHeight)
      }
    },
    [columns]
  )

  return { elementRef, height }
}

function useJobsQuery(lastChanged) {
  const language = useLanguage()
  const [jobsQueryString] = useJobsQueryString()
  const { employmentType, education, page, searchString, minHoursPerWeek, maxHoursPerWeek, experienceLevel, salaryScale, jobArea, division, referralType } = jobsQueryString

  const filters = { experienceLevel, searchString, employmentType, education, minHoursPerWeek, maxHoursPerWeek, salaryScale, jobArea, division, referralType }

  const { data, isError, isLoading, isFetching, isPending } = useQuery({
    queryKey: [experienceLevel, employmentType, education, page, language, searchString, minHoursPerWeek, maxHoursPerWeek, jobArea, division, salaryScale, referralType, routeMap.api.v1.jobs()],
    queryFn: () => fetchJobs({ page, language, filters, lastChanged }),
    placeholderData: keepPreviousData,
    retryOnMount: false,
    retry: false
  })

  const jobs = data?.hits?.hits ?? []
  const jobsAmount = data?.hits?.total?.value ?? 0

  const status = { isError, isLoading, isFetching, isPending }

  return { jobs, jobsAmount, status }
}

async function fetchJobs({ page, language, filters, lastChanged }) {
  try {
    const response = await fetch(routeMap.api.v1.jobs(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ filters, page, language })
    })

    const result = await handleResponse(response)

    const jobsAmount = result?.hits?.total?.value || 0

    lastChanged && trackFilter({
      lastchanged: lastChanged,
      searchterm: [...filters?.jobArea || [], ...filters?.education || [], language, filters?.searchString || [], language].filter(Boolean).join('|'),
      recordcount: Math.min(jobsAmount, JOBS_PER_PAGE),
      maxresults: jobsAmount
    })

    return result
  } catch (e) {
    reportClientError(e)
  }
}

function prepareFilters(filters, values, { setQueryString, debouncedSetQueryString, setLastChanged }) {
  const { employmentType, education, hoursPerWeek, jobArea, experienceLevel, salaryScale, division, referralType } = filters

  return {
    employmentType: {
      name: employmentType.name,
      currentValue: values.employmentType,
      title: employmentType.title,
      options: employmentType.options.map(x => ({
        ...x,
        checked: values.employmentType.includes(x.value)
      })
      ),
      handleChange: value => handleCheckboxFilterChange(value, employmentType.name)
    },
    jobArea: {
      name: jobArea.name,
      currentValue: values.jobArea,
      title: jobArea.title,
      options: jobArea.options.map(x => ({
        ...x,
        checked: values.jobArea.includes(x.value)
      })
      ),
      handleChange: value => handleCheckboxFilterChange(value, jobArea.name)
    },
    education: {
      name: education.name,
      currentValue: values.education,
      title: education.title,
      options: education.options.map(x => ({
        ...x,
        checked: values.education.includes(x.value)
      })
      ),
      handleChange: value => handleCheckboxFilterChange(value, education.name)
    },
    experienceLevel: {
      name: experienceLevel.name,
      currentValue: values.experienceLevel,
      title: experienceLevel.title,
      options: experienceLevel.options.map(x => ({
        ...x,
        checked: values.experienceLevel.includes(x.value)
      })
      ),
      handleChange: value => handleCheckboxFilterChange(value, experienceLevel.name)
    },

    ...(salaryScale && {
      salaryScale: {
        name: salaryScale.name,
        currentValue: values.salaryScale,
        title: salaryScale.title,
        options: salaryScale.options.map(x => ({
          ...x,
          checked: values.salaryScale.includes(x.value)
        })
        ),
        handleChange: value => handleCheckboxFilterChange(value, salaryScale.name)
      }
    }),

    ...(division && {
      division: {
        name: division.name,
        currentValue: values.division,
        title: division.title,
        options: division.options.map(x => ({
          ...x,
          checked: values.division.includes(x.value)
        })
        ),
        handleChange: value => handleCheckboxFilterChange(value, division.name)
      }
    }),

    ...(referralType && {
      referralType: {
        name: referralType.name,
        currentValue: values.referralType,
        title: referralType.title,
        options: referralType.options.map(x => ({
          ...x,
          checked: values.referralType === x.value
        })
        ),
        handleChange: value => handleRadioButtonFilterChange(value, referralType.name)
      }
    }),

    hoursPerWeek: {
      name: 'hoursPerWeek',
      start: hoursPerWeek.start,
      end: hoursPerWeek.end,
      gap: hoursPerWeek.gap,
      currentMinValue: values.minHoursPerWeek,
      currentMaxValue: values.maxHoursPerWeek,
      title: hoursPerWeek.title,
      range: hoursPerWeek.range,
      handleMinChange: value => handleRangeFilterChange(value, 'minHoursPerWeek'),
      handleMaxChange: value => handleRangeFilterChange(value, 'maxHoursPerWeek')
    },
  }

  function handleCheckboxFilterChange(value, filterName) {
    setQueryString(x => {
      const oldValue = x?.[filterName] || []
      const newValue = oldValue.includes(value) ? oldValue.filter(x => x !== value) : [...oldValue, value]

      return { ...x, [filterName]: newValue, page: undefined }
    })

    setLastChanged(filterName)
  }

  function handleRadioButtonFilterChange(value, filterName) {
    setQueryString(x => {
      return { ...x, [filterName]: value, page: undefined }
    })

    setLastChanged(filterName)
  }

  function handleRangeFilterChange(value, filterName) {
    debouncedSetQueryString(x => ({ ...x, [filterName]: value }))
    setLastChanged(filterName)
  }

}
