import { useEvent } from './useEvent'

export function useDebounced(f, config = { interval: 300 }) {
  const intervalRef = React.useRef(null)

  return useEvent(
    (...args) => {
      clearTimeout(intervalRef.current)
      intervalRef.current = setTimeout(
        () => { f(...args) },
        config.interval
      )
    }
  )
}
