import { unlerp } from '@kaliber/math'
import { useOnChange } from '/machinery/useOnChange'

import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './FilterRange.css'

export function FilterRange({ title, currentMinValue, currentMaxValue, handleMinChange, handleMaxChange, start, end, gap }) {
  return (
    <div className={styles.component}>
      <HeadingSm h='3'>{title}</HeadingSm>
      <RangeField
        {...{ currentMinValue, currentMaxValue, handleMinChange, handleMaxChange, start, end, gap }}
        layoutClassName={styles.rangeFieldLayout}
      />
    </div>
  )
}

function RangeField({ currentMinValue, currentMaxValue, start, end, handleMinChange, handleMaxChange, gap, layoutClassName }) {
  const [min, setMin] = React.useState(currentMinValue)
  const [max, setMax] = React.useState(currentMaxValue)

  useOnChange({ ifChanged: currentMinValue, callback: setMin })
  useOnChange({ ifChanged: currentMaxValue, callback: setMax })

  return (
    <div className={cx(styles.componentRangeField, layoutClassName)}>
      <RangeLabels layoutClassName={styles.rangeLabelsLayout} {...{ start, end }} />
      <RangeSpan layoutClassName={styles.rangeSpanLayout} {...{ min, max, start, end }} />
      <RangeTrack layoutClassName={styles.rangeTrackLayout} />
      <RangeInputs
        onMinChange={value => {
          const newValue = Math.min(max - gap, value)
          setMin(newValue)
          handleMinChange(newValue)
        }}
        onMaxChange={value => {
          const newValue = Math.max(min + gap, value)
          setMax(newValue)
          handleMaxChange(newValue)
        }}
        layoutClassName={styles.rangeInputsLayout}
        {...{ min, max, start, end }}
      />
      <RangeIndicators
        layoutClassName={styles.rangeIndicatorsLayout}
        {...{ min, max, start, end }}
      />
    </div>
  )
}

function RangeLabels({ start, end, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeLabels, layoutClassName)}>
      <RangeLabel position='start' label={start} />
      <RangeLabel position='end' label={end} />
    </div>
  )
}

function RangeLabel({ position, label }) {
  return <div className={cx(styles.componentRangeLabel, styles[position])}>{label}</div>
}

function RangeTrack({ layoutClassName }) {
  return <div className={cx(styles.componentRangeTrack, layoutClassName)} />
}

function RangeSpan({ min, max, start, end, layoutClassName }) {
  const startProgress = unlerp({ start, end, input: min })
  const endProgress = unlerp({ start, end, input: max })

  return (
    <div className={cx(styles.componentRangeSpan, layoutClassName)}>
      <div
        className={cx(styles.rangeSpanLine, styles.rangeSpanLineLayout)}
        style={{
          left: asPercentage(startProgress),
          width: asPercentage(endProgress - startProgress),
        }}
      />
    </div>
  )
}

function RangeInputs({ min, max, start, end, onMinChange, onMaxChange, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeInputs, layoutClassName)}>
      <RangeInput value={min} min={start} max={end} onChange={onMinChange} />
      <RangeInput value={max} min={start} max={end} onChange={onMaxChange} />
    </div>
  )
}

function RangeInput({ value, onChange, min, max }) {
  return (
    <input
      type='range'
      {...{ value, min, max }}
      className={styles.componentRangeInput}
      onChange={e =>
        onChange(Number(e.currentTarget.value))
      }
    />
  )
}

function RangeIndicators({ min, max, start, end, layoutClassName }) {
  return (
    <div className={cx(styles.componentRangeIndicators, layoutClassName)}>
      <RangeIndicator label={min} progress={unlerp({ start, end, input: min })} />
      <RangeIndicator label={max} progress={unlerp({ start, end, input: max })} />
    </div>
  )
}

function RangeIndicator({ progress, label }) {
  return (
    <span className={styles.componentRangeIndicator} style={{ left: asPercentage(progress) }}>
      {label}
    </span>
  )
}

function asPercentage(value) {
  return `${value * 100}%`
}
