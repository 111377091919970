import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { JobsGridAndFilter } from '/features/pageOnly/JobsOverview/JobsGridAndFilter'

const client = new QueryClient()

export default function JobsGridAndFilterApp({ initialJobs, jobFilters }) {
  return (
    <QueryClientProvider {...{ client }}>
      <JobsGridAndFilter {...{  initialJobs, jobFilters }} />
    </QueryClientProvider>
  )
}
