import { useMediaQuery } from '@kaliber/use-media-query'
import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { useGetCompanyInfo } from '/machinery/useGetCompanyInfo'
import { makeSlug } from '/machinery/makeSlug'
import { useCheckStringLength } from '/machinery/useCheckStringLength'
import { useNormalizedJobInfoTags } from '/features/pageOnly/useNormalizedJobInfoTags'

import { Tag, TagWithLogo } from '/features/buildingBlocks/Tag'
import { CardCursor, useCardCursorProps } from '/features/buildingBlocks/CardCursor'

import mediaStyles from '/cssGlobal/media.css'
import styles from './JobCard.css'

export function JobCard({ job, hideCursor, layoutClassName = undefined }) {
  const language = useLanguage()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const { title, id: jobId } = job

  const { elementRef, x, y, isHovering, setIsHovering } = useCardCursorProps()

  const href = job.kind === 'INTERNAL'
    ? routeMap.app.jobs.internalDetail({ language, jobTitle: makeSlug({ input: title, language }), jobId })
    : routeMap.app.jobs.detail({ language, jobTitle: makeSlug({ input: title, language }), jobId })

  return (
    <div
      ref={elementRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={cx(styles.component, layoutClassName)}
    >
      {isViewportMd && <CardCursor isHidden={hideCursor} layoutClassName={styles.jobCardCursorLayout} {...{ x, y, isHovering }} />}
      <Title layoutClassName={styles.titleLayout} {...{ title, href }} />
      <Meta {...{ job }} />
    </div>
  )
}

function Title({ title, href, layoutClassName = undefined }) {
  const hasLongWord = useCheckStringLength({ strings: [title], allowedLength: 15 })

  return (
    <h4 className={cx(styles.componentTitle, hasLongWord && styles.hasLongWord, layoutClassName)}>
      <a data-x='link-to-job-detail' className={styles.link} {...{ href }}>
        <span data-content={title} className={styles.underline}>{title}</span>
      </a>
    </h4>
  )
}

function Meta({ job }) {
  const { jobArea, hoursPerWeek, employmentType, salaryScale = undefined, canBeReferred = undefined, company = undefined } = job
  const companyInfo = useGetCompanyInfo(company)
  const tags = useNormalizedJobInfoTags({ jobArea, hoursPerWeek, employmentType, salaryScale, canBeReferred })

  return (
    <div className={styles.componentMeta}>
      {companyInfo && (
        <div className={styles.subsidiaryContainer}>
          <TagWithLogo icon={companyInfo.logo} colorScheme='white' layoutClassName={styles.jobAreaTagLayout} />
        </div>
      )}
      {tags.map(({ label, colorScheme }, i) => (
        label && <Tag key={i} layoutClassName={styles.jobAreaTagLayout} {...{ label, colorScheme }} />
      ))}
    </div>
  )
}
