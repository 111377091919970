import { getPagination } from '@kaliber/pagination'
import { useJobsQueryString } from '/machinery/useJobsQueryString'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Pagination.css'

import arrowRightIcon from '/images/icons/arrow-right.raw.svg'
import arrowLeftIcon from '/images/icons/arrow-left.raw.svg'

export function Pagination({ currentPage, maxPages, scrollToRef, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <span className={styles.paginationLabel}>{__`page`} {currentPage} {__`of`} {maxPages}</span>
      <Navigation layoutClassName={styles.navigationLayout} {...{ currentPage, maxPages, scrollToRef }} />
    </div>
  )
}

function Navigation({ currentPage, maxPages, scrollToRef, layoutClassName }) {
  const pagination = getPagination({
    current: currentPage,
    max: maxPages,
    padding: 1
  })

  return (
    <nav className={cx(styles.componentNavigation, layoutClassName)}>
      <PaginationButtonPrevious layoutClassName={styles.buttonPreviousLayout} {...{ currentPage, scrollToRef }} />
      {pagination.map((page, index) => page
        ? <PaginationButton key={index} {...{ page, currentPage, scrollToRef }} />
        : <span key={index} className={styles.placeholderLink}>…</span>
      )}
      <PaginationButtonNext layoutClassName={styles.buttonNextLayout} {...{ currentPage, maxPages, scrollToRef }} />
    </nav>
  )
}

function PaginationButton({ page, currentPage, scrollToRef }) {
  return (
    <PaginationButtonBase {...{ page, currentPage, scrollToRef }}>{page}</PaginationButtonBase>
  )
}

function PaginationButtonNext({ currentPage, maxPages, scrollToRef, layoutClassName }) {
  const { __ } = useTranslate()
  const page = currentPage + 1
  const disabled = currentPage >= maxPages

  return (
    <PaginationButtonBase className={cx(styles.componentButtonNext, layoutClassName)} {...{ page, currentPage, scrollToRef, disabled }}>{__`next-page`} <Icon icon={arrowRightIcon} layoutClassName={styles.iconLayout} /></PaginationButtonBase>
  )
}

function PaginationButtonPrevious({ currentPage, layoutClassName, scrollToRef }) {
  const { __ } = useTranslate()
  const page = currentPage - 1
  const disabled = currentPage <= 1

  return (
    <PaginationButtonBase className={cx(styles.componentButtonPrevious, layoutClassName)} {...{ page, currentPage, scrollToRef, disabled }}><Icon icon={arrowLeftIcon} layoutClassName={styles.iconLayout} /> {__`previous-page`}</PaginationButtonBase>
  )
}

function PaginationButtonBase({ page, currentPage, scrollToRef, children, disabled = undefined, className = undefined }) {
  const [, setQueryString] = useJobsQueryString()

  return (
    <button className={cx(styles.componentButtonBase, currentPage === page && styles.active, className)} onClick={handleClick} {...{ disabled }}>{children}</button>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, page }))

    const top = scrollToRef.current?.offsetTop - 165
    window.scrollTo({ top, behavior: 'instant' })
  }
}
