import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { ensureInteger } from './ensureInteger'
import { ensureArray } from './ensureArray'
import { ensureString } from './ensureString'
import { parsePageNumber } from './parsePageNumber'

/**
 * @returns {[{
 * jobArea: string[],
 * education: string[],
 * division: string[],
 * expertise: string[],
 * experienceLevel: string[],
 * employmentType: string[],
 * company: string[],
 * salaryScale: string[],
 * minHoursPerWeek: number,
 * maxHoursPerWeek: number,
 * searchString: string,
 * referralType: string,
 * page: number }, (x: any) => void]}
 */
export function useJobsQueryString() {
  const [{ page: rawPage = 1, ...filters }, setQueryString] = useQueryString()

  const normalizedFilters = normalizeFilters(filters)
  const jobsQueryString = { ...normalizedFilters, page: parsePageNumber(rawPage) }

  return [jobsQueryString, setQueryString]
}

export function normalizeFilters(filters) {
  const {
    jobArea: rawJobArea,
    education: rawEducation,
    expertise: rawExpertise,
    employmentType: rawEmploymentType,
    company: rawCompany,
    searchString: rawSearchString,
    minHoursPerWeek: rawMinHoursPerWeek,
    maxHoursPerWeek: rawMaxHoursPerWeek,
    experienceLevel: rawExperienceLevel,
    salaryScale: rawSalaryScale,
    division: rawDivision,
    referralType: rawreferralType
  } = filters

  const jobArea = ensureArray(rawJobArea)
  const experienceLevel = ensureArray(rawExperienceLevel)
  const division = ensureArray(rawDivision)
  const salaryScale = ensureArray(rawSalaryScale)
  const company = ensureArray(rawCompany)
  const education = ensureArray(rawEducation)
  const expertise = ensureArray(rawExpertise)
  const employmentType = ensureArray(rawEmploymentType)
  const searchString = ensureString(rawSearchString, '')
  const minHoursPerWeek = ensureInteger(rawMinHoursPerWeek, 8)
  const maxHoursPerWeek = ensureInteger(rawMaxHoursPerWeek, 40)
  const referralType = ensureString(rawreferralType, '')

  return { jobArea, division, education, expertise, employmentType, searchString, company, maxHoursPerWeek, minHoursPerWeek, experienceLevel, salaryScale, referralType }
}
