import styles from './RadioButton.css'

export function RadioButton({ label, name, checked, value, eventHandlers = {}, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <input
        id={label}
        className={cx(styles.inputFieldRadio, checked && styles.checked)}
        type="radio"
        {...{ value, name, checked }}
        {...eventHandlers}
      />
      <label htmlFor={label} className={styles.labelRadio}>
        {label}
      </label>
    </div>
  )
}
