import { useSpring, animated } from '@react-spring/web'

import { Icon } from '/features/buildingBlocks/Icon'

import iconArrowRight from '/images/icons/arrow-right.raw.svg'

import styles from './CardCursor.css'

export function CardCursor({ x, y, isHovering, isHidden = undefined, layoutClassName = undefined }) {
  const style = useSpring({
    x: x - 80,
    y: y - 80,
    opacity: isHidden
      ? 0
      : isHovering
        ? 1
        : 0.7,
    scale: isHovering ? 1 : 0.4,
    config: { mass: 1, tension: 500, friction: 50 },
  })

  return (
    <animated.span className={cx(styles.component, layoutClassName)} {...{ style }}>
      <Icon icon={iconArrowRight} layoutClassName={styles.iconLayout} />
    </animated.span>
  )
}

export function useCardCursorProps() {
  const elementRef = React.useRef(null)
  const [cursorCoords, setCursorCoords] = React.useState({ x: 0, y: 0 })
  const [relativeCursorCoords, setRelativeCursorCoords] = React.useState({ x: 0, y: 0 })
  const [isHovering, setIsHovering] = React.useState(false)
  const { x, y } = relativeCursorCoords

  React.useEffect(
    () => {
      window.addEventListener('mousemove', handleMouseMove)

      return () => window.removeEventListener('mousemove', handleMouseMove)

      function handleMouseMove(e) {
        setCursorCoords({ x: e.clientX, y: e.clientY })
      }
    },
    []
  )

  React.useEffect(
    () => {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        const { x: clientX, y: clientY } = elementRef.current.getBoundingClientRect()
        const x = cursorCoords.x - clientX + 6
        const y = cursorCoords.y - clientY + 6

        setRelativeCursorCoords({ x, y })
      }
    },
    [cursorCoords]
  )

  return { elementRef, x, y, isHovering, setIsHovering }
}
