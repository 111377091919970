import styles from './Checkbox.css'

export function Checkbox({ label, checked, name, value, required = false, eventHandlers = {}, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <input
        id={name}
        className={cx(styles.inputFieldCheckbox, value && styles.checked)}
        type="checkbox"
        {...{ value, name, required, checked }}
        {...eventHandlers}
      />
      <label htmlFor={name} className={styles.labelCheckbox}>
        {label}
      </label>
    </div>
  )
}
