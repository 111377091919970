import { useTranslate } from '/machinery/I18n'
import { useJobsQueryString } from '/machinery/useJobsQueryString'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { Icon } from '/features/buildingBlocks/Icon'
import { JobsOverviewFilter } from '/features/pageOnly/JobsOverview/JobsOverviewFilter'

import styles from './JobSearchbarAndFilter.css'

import searchIcon from '/images/icons/search.raw.svg'

export function JobSearchbarAndFilter({ filters, handleDrawerToggle, drawerIsOpen }) {
  return (
    <section data-x='searchbar-header' className={styles.component}>
      <Searchbar />

      {filters && (
        <ContainerXl>
          <JobsOverviewFilter {...{ filters, handleDrawerToggle, drawerIsOpen }} />
        </ContainerXl>
      )}
    </section>
  )
}

function Searchbar() {
  const { __ } = useTranslate()
  const searchInputRef = React.useRef(undefined)
  const [{ searchString = '' }, setQueryString] = useJobsQueryString()

  return (
    <div className={styles.componentSearchbar}>
      <ContainerXl>
        <form onSubmit={handleSubmit}>
          <div className={styles.searchBarContainer}>
            <input
              autoFocus
              ref={searchInputRef}
              placeholder={__`search-jobs`}
              defaultValue={searchString}
              className={styles.searchBarInput}
            />
            <button aria-label='search button' data-x='click-search' onClick={handleSubmit} className={styles.searchButton}>
              <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />
            </button>
          </div>
        </form>
      </ContainerXl>
    </div>
  )

  function handleSubmit(e) {
    e.preventDefault()
    const searchString = searchInputRef.current.value
    setQueryString(x => ({ ...x, searchString, page: undefined }))
  }
}
