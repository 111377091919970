import { useIsIntersecting } from '@kaliber/use-is-intersecting'

import styles from './FilterCheckbox.css'

export function FilterCheckbox({ checked, label, value, root, onChange, layoutClassName = undefined }) {
  const { ref: isIntersectingRef, isIntersecting } = useIsIntersecting({ root, threshold: 1.0 })

  return (
    <li
      ref={isIntersectingRef}
      className={cx(styles.component, checked && styles.checked, isIntersecting && styles.isVisible, layoutClassName)}
    >
      <input
        id={value}
        data-x='toggle-filter'
        className={styles.filterCheckbox}
        type='checkbox'
        {...{ onChange, checked }}
      />
      <label className={styles.filterLabel} htmlFor={value}>{label}</label>
    </li>
  )
}
