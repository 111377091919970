import { useTranslate } from '/machinery/I18n'
import { FloatingPortal, FloatingOverlay, useFloating, useTransitionStyles, autoUpdate, useDismiss, useInteractions } from '@floating-ui/react'
import { useJobsQueryString } from '/machinery/useJobsQueryString'
import { useMediaQuery } from '@kaliber/use-media-query'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { Checkbox } from '/features/buildingBlocks/Checkbox'
import { useUserInfo } from '/machinery/UserInfoContext'
import { FilterCheckbox } from '/features/pageOnly/JobsOverview/FilterCheckbox'
import { RadioButton } from '/features/buildingBlocks/RadioButton'
import { FilterRange } from '/features/pageOnly/JobsOverview/FilterRange'
import { ResetFiltersButton } from '/features/pageOnly/JobsOverview/ResetFiltersButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './JobsOverviewFilter.css'

import iconPlus from '/images/icons/plus.raw.svg'
import iconMark from '/images/icons/mark-bold.raw.svg'

export function JobsOverviewFilter({ filters, handleDrawerToggle, drawerIsOpen }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const [, setQueryString] = useJobsQueryString()

  return (
    <div className={styles.component}>
      {isViewportMd && <JobAreaFilter filter={filters.jobArea} />}
      <DrawerToggleButton onClick={handleDrawerToggle} layoutClassName={styles.drawerButtonLayout} />
      <Drawer onToggleDrawer={handleDrawerToggle} {...{ drawerIsOpen, onFilterReset, filters }} />
    </div>
  )

  function onFilterReset() {
    setQueryString({})
  }
}

function JobAreaFilter({ filter }) {
  const [root, setRoot] = React.useState(null)
  const { options, handleChange } = filter

  return (
    <ul ref={setRoot} className={styles.componentJobAreaFilter}>
      {options.map(({ value, label, checked }, i) => (
        <FilterCheckbox
          key={i}
          onChange={() => handleChange(value)}
          {...{ label, value, root, checked }}
        />
      ))}
    </ul>
  )
}

function DrawerToggleButton({ onClick, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const { __ } = useTranslate()


  return (
    <button data-x='click-open-filters-drawer' className={cx(styles.componentDrawerToggleButton, layoutClassName)} {...{ onClick }}>
      {isViewportMd ? __`more-filters` : __`filter`}
      <Icon icon={iconPlus} layoutClassName={styles.iconLayout} />
    </button>
  )
}

function Drawer({ filters, drawerIsOpen, onToggleDrawer, onFilterReset }) {
  const { isLoggedIn } = useUserInfo()
  const { jobArea, education, hoursPerWeek, employmentType, experienceLevel, salaryScale, division, referralType } = filters
  const { strategy, refs, context } = useFloating({
    strategy: 'fixed',
    open: drawerIsOpen,
    onOpenChange: onToggleDrawer,
    whileElementsMounted: autoUpdate
  })

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    common: { transform: 'translateX(100%)', transformOrigin: 'right' },
    open: { transform: 'translateX(0)' },
    close: { transform: 'translateX(100%)' },
    duration: 500,
  })

  const style = {
    position: strategy,
    ...transitionStyles
  }

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return (
    <FloatingPortal>
      <FloatingOverlay
        // eslint-disable-next-line @kaliber/layout-class-name
        className={cx(styles.componentDrawer, drawerIsOpen && styles.open)}
        lockScroll={drawerIsOpen}
        {...getReferenceProps()}
      >
        {isMounted && (
          <div
            ref={refs.setFloating}
            aria-hidden={!drawerIsOpen}
            className={styles.drawerContainer}
            {...getFloatingProps()}
            {...{ style }}
          >
            <div className={styles.drawerToggleContainer}>
              <ResetFiltersButton onClick={onFilterReset} layoutClassName={styles.resetFilterLayout} />
              <CloseDrawerButton onClick={onToggleDrawer} />
            </div>
            <div className={styles.drawerItemsContainer}>
              {Boolean(jobArea?.options?.length) && <FilterGroupCheckbox {...jobArea} />}
              {isLoggedIn && Boolean(division?.options?.length) && <FilterGroupCheckbox {...division} />}
              {Boolean(education?.options?.length) && <FilterGroupCheckbox {...education} />}
              {Boolean(experienceLevel?.options?.length) && <FilterGroupCheckbox {...experienceLevel} />}
              {Boolean(employmentType?.options?.length) && <FilterGroupCheckbox {...employmentType} />}
              {isLoggedIn && Boolean(referralType?.options?.length) && <FilterGroupRadioButton {...referralType} />}
              {Boolean(hoursPerWeek) && <FilterRange {...hoursPerWeek} />}
              {isLoggedIn && Boolean(salaryScale?.options?.length) && <FilterGroupCheckbox {...salaryScale} />}
            </div>
          </div>
        )}
      </FloatingOverlay>
    </FloatingPortal>
  )
}

function FilterGroupCheckbox({ options, handleChange, title = undefined }) {
  return (
    <FilterGroupBase {...{ title }}>
      {options.map(({ label, value, checked }, i) => (
        <li key={i}>
          <Checkbox
            {...{ label, checked, value }}
            name={value}
            eventHandlers={{ onChange: () => handleChange(value) }}
          />
        </li>
      ))}
    </FilterGroupBase>
  )
}

function FilterGroupRadioButton({ options, handleChange, title = undefined }) {
  return (
    <FilterGroupBase {...{ title }}>
      {options.map(({ label, value, checked }, i) => (
        <li key={i}>
          <RadioButton
            {...{ label, value, checked }}
            name={value}
            eventHandlers={{ onChange: () => handleChange(value) }}
          />
        </li>
      ))}
    </FilterGroupBase>
  )
}

function FilterGroupBase({ title = undefined, children }) {
  return (
    <div className={styles.componentFilterGroupBase}>
      {title && <HeadingSm h='3'>{title}</HeadingSm>}
      <ul className={styles.filterList}>
        {children}
      </ul>
    </div>
  )
}

function CloseDrawerButton({ onClick }) {
  return (
    <button data-x='click-close-filters-drawer' className={styles.componentCloseDrawerButton} {...{ onClick }}>
      <Icon icon={iconMark} layoutClassName={styles.iconLayout} />
    </button>
  )
}
